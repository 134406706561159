
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function Hero() {
  const navigate = useNavigate();
    return (
  <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            marginTop: "5%",
            fontSize: "60px",
            color: "#080823",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          A Simpler and Cheaper Way to Diversify.
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            color: "#080823",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Search for user-curated portfolios, custom built with safety and
          security in mind.
        </Typography>
        <Box
          sx={{
            height: "150px",
            width: "300px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: "25%",
              height: "75px",
              width: "275px",
              background: "#26317A",
              boxShadow: "0px 0px 18.5px 3px #008CD780",
              borderRadius: "25px",
              textTransform: "none",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                background: "#0077B6",
                height: "80px",
                width: "285px",
                boxShadow:
                  "0px 0px 18.5px 10px #008CD780, 0px 0px 28px 0px #03045E80 inset",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                color: "#FFFFFF",
                fontWeight: "700px",
                display: "flex",
              }}
              onClick={() => navigate("/demo")}
            >
              Browse Ports
            </Typography>
          </Button>
        </Box>
        <Typography
          sx={{
            marginTop: "2%",
            fontSize: "28px",
            color: "#080823",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Start investing with as little as $5.
        </Typography>
      </Box>
    );
  }