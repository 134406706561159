import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";  
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import * as Mui from "@mui/material";
import Grid from "@mui/material/Grid2";
import {Card} from "../Cards/cardv2";

import {searchPublicPorts,searchPersonalPorts} from "../user.js";
function BrowsePortsSearchBar({window, setPortDatas, view}) {
    console.log(window);
    const itemsInRow = Math.floor((window - 460)/(221+64))
    console.log(itemsInRow);    
    const width = (itemsInRow * 221) + ((itemsInRow - 1) * 64);
    console.log(width);
    const [selectedSortOption, setSelectedSortOption] = React.useState("title");
    const [direction, setDirection] = React.useState("asc");
    const [searchQuery, setSearchQuery] = React.useState("");
    const [pendingSelection, setPendingSelection] = React.useState("ports");

    React.useEffect(() => {
      const fetchData = async () => {
        console.log("searching:",selectedSortOption);
        let ports = await searchPersonalPorts(searchQuery, selectedSortOption, direction, 0, null);
        console.log("searching:",ports);
        setPortDatas(ports.content);
      };
      fetchData();
    }, [selectedSortOption, direction, searchQuery,pendingSelection]);

const handleSortChange = (event) => {
  setSelectedSortOption(event.target.value);
};
const handleSearchChange = (event) => {
  setSearchQuery(event.target.value); // Update the searchQuery state
};

const handleDirectionToggle = () => {
  if (direction === "desc") {
    setDirection("asc");
  }
  else {
    setDirection("desc");
  }
};
  return (
    <Mui.Box
      sx={{
        width: width,
      }}
    >
      <Mui.Box
        sx={{
          width: "100%",
          display: "flex",
          displayDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Mui.Typography
          sx={{
            color: "#080823",
            fontWeight: "700",
            fontSize: "28px",
          }}
        >
          Browse Ports
        </Mui.Typography>
        <Mui.Box
        sx = {{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
        >
          {view === "pending" &&(<PortToggles pendingSelection={pendingSelection} setPendingSelection={setPendingSelection}/>)}
        <Mui.Select
  id="dropdown-basic"
  value={selectedSortOption}
  onChange={handleSortChange}
  sx={{
    width: "190px",
    height: "35px",
    borderRadius: "5px",
    fontSize: "20px",
    border: "0.5px solid #08082380",
    padding: "0 8px",
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  }}
> <Mui.MenuItem value="title">Title</Mui.MenuItem>
  {/* <Mui.MenuItem value="value">Value</Mui.MenuItem> */}
  <Mui.MenuItem value="risk">Risk</Mui.MenuItem>
  <Mui.MenuItem value="annualReturn">Annual Return</Mui.MenuItem>
  <Mui.MenuItem value="dailyPnl">Daily Change</Mui.MenuItem>
  
</Mui.Select>
<Mui.IconButton
  onClick={handleDirectionToggle}
  sx={{
    width: "35px",
    height: "35px",
    borderRadius: "5px",
    border: "0.5px solid #08082380",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  }}
>
  {direction === "asc" ? (
    <ArrowUpwardIcon
      sx={{
        fontSize: "20px",
        color: "#08082380",
      }}
    />
  ) : (
    <ArrowDownwardIcon
      sx={{
        fontSize: "20px",
        color: "#08082380",
      }}
    />
  )}
</Mui.IconButton>
</Mui.Box>
      </Mui.Box>
      <Mui.Box
        sx={{
          width: "100%",
          display: "flex",
          displayDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
          gap: "10px",
        }}
      >
        <Mui.TextField
          id="outlined-basic"
          placeholder="Search Ports"
          value={searchQuery} // Bind searchQuery state to the Mui.TextField
          onChange={handleSearchChange} // Update state on input change
          sx={{
            width: "100%", // Set the width of the Mui.TextField
            ".MuiOutlinedInput-root": {
              height: "35px", // Set the height of the input field
              borderRadius: "5px", // Rounded corners
              "& fieldset": {
                border: "0.5px solid #08082380", // Border styling
              },
            },
            ".MuiInputBase-input": {
              padding: "8px", // Adjust padding inside the input
              paddingLeft: "30px",
              fontSize: "20px", // Adjust font size if necessary
            },
          }}
        />
        {/* <Mui.Select
  id="dropdown-basic"
  value={selectedFilterOption}
  onChange={handleFilterChange}
  sx={{
    width: "258px",
    height: "35px",
    borderRadius: "5px",
    fontSize: "20px",
    border: "0.5px solid #08082380",
    padding: "0 8px",
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  }}
>
  <Mui.MenuItem value="author">Author</Mui.MenuItem>
  <Mui.MenuItem value="title">Title</Mui.MenuItem>
</Mui.Select> */}
      </Mui.Box>
    </Mui.Box>
  );
}

function PortToggles({pendingSelection, setPendingSelection}){

    const handleSortChange = (event) => {
      setPendingSelection(event.target.value);
      };
  
    return(
      <Mui.Select
      id="dropdown-basic"
      value={pendingSelection}
      onChange={handleSortChange}
      sx={{
        width: "190px",
        height: "35px",
        borderRadius: "5px",
        fontSize: "20px",
        border: "0.5px solid #08082380",
        padding: "0 8px",
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    > <Mui.MenuItem value="ports">Ports</Mui.MenuItem>
      <Mui.MenuItem value="stocks">Stocks</Mui.MenuItem>
      
    </Mui.Select>
    )
  }
  
  
  function PortMarket({setPortData, view}) {
    const [portDatas, setPortDatas] = React.useState([]);
    console.log("updating");
  
  
    return (
      <Grid
      container
      sx={{
        position: "relative",
        backgroundColor: "#F6F5F5",
        padding: "10px 20px 10px 20px",
        flexGrow: 1,
        display: "flex",
        alignContent: "flex-start",
        justifyContent: "center",
        // Align rows to the top
      }}
    >
      <BrowsePortsSearchBar window = {window.innerWidth} setPortDatas= {setPortDatas} view={view}/>
      <Grid
        container
        spacing={8}
        sx={{
          paddingTop: "20px",
          paddingLeft: "3.4%",
          paddingRight: "3%",
          display: "grid", // Switch to CSS grid for precise row alignment
          gridTemplateColumns: "repeat(auto-fit, 221px)", // Auto-adjust column count
          justifyContent: "center",
          width: "100%",
          height: "700px", // Fixed height for the grid container
          overflowY: "auto", // Enable vertical scrolling
          overflowX: "hidden", // Prevent horizontal scrolling
          scrollbarWidth: "none", // For Firefox
          alignItems: "start",
          justifyContent: "left",
      "&::-webkit-scrollbar": { // For Chrome, Safari, Edge
        display: "none",
      },
        }}
      >
        {portDatas.map((portData) => {
          return (
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center", // Center items within each cell
                alignItems: "center",
              }}
            >
              <Card portData={portData} setIsClicked={setPortData} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
    );
  }


  export default PortMarket;