import styles from './OurStory.module.css';
import John from './john_headshot.jpg'
import Advaith from './advaith_headshot.jpg'
import kaiden from './kaiden_headshot.jpg'
export default function Story() {
    return (
      <section className={styles.story}>
        <div className={styles.storyContainer}>
          <h2 className={styles.storyTitle}>Our Story</h2>
          <p className={styles.storyIntro}>
          The story of Ports starts at Purdue, where John and Kaiden met at a run club. They participated in a datathon, where they met Advaith, and the three of them went on to win it together. Over the next year, they began working together on an algo fund, trading in sim at first and raising enough to live trade and understand market complexities.

When talking to friends and family about what they were working on, they realized there was a large discrepancy in market knowledge, and wanted to find a way to connect experienced individuals with newcomers in one integrated solution. Working with both leaders in the industry, as well as students and investors, they developed a solution that is simple but powerful and tackles this difficult challenge.

          </p>
          
          <div className={styles.storyContent}>
            <div className={styles.storyBlock}>
              <img 
                src={John} 
                alt="Story illustration 1"
                className={styles.storyImage}
              />
              <p className={styles.storyText}>
              John Reilly  is a senior at NYU studying mathematics and economics, with a minor in business. On the side, he enjoys beekeeping and photography.
              </p>
            </div>
  
            <div className={`${styles.storyBlock} ${styles.reverse}`}>
              <img 
                src={Advaith} 
                alt="Story illustration 2"
                className={styles.storyImage}
              />
              <p className={styles.storyText}>
              Advaith Hari is a senior at Purdue studying Computer Science and Data Science in the Honors College. He loves playing classic video games in his free time. 
              </p>
              
            </div>
  
            <div className={styles.storyBlock}>
              <img 
                src={kaiden}
                alt="Story illustration 3"
                className={styles.storyImage}
              />
              <p className={styles.storyText}>
              Kaiden Krenek is also a senior at Purdue majoring in data science, with a minor in finance. He loves to run in his free time and has two cats.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }