import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
export function SignUpButton() {
    const navigate = useNavigate();
    return (
      <Box
        onClick={() => navigate("/register")}
        sx={{
          height: "55px",
          width: "264px",
          // display: "inline-block",
          borderRadius: "15px",
          background: "linear-gradient(141.01deg, #00B4D8 21.7%, #0077B6 77.63%)", // Gradient border
          padding: "3px 3px 3px 3px",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            background: "linear-gradient(135deg, #0077B6 -0.93%, #DAE8EA 75%)",
            boxShadow: "0px 0px 5px 1px #FFFFFFBF",
            "& .MuiTypography-root": {
              fontSize: "30px", // Increase text size on hover
            },
          },
          "@media (max-width: 606px)": {
            width: "auto",
        },
        "@media (max-width: 392px)": {
            height: "auto",
        },
        }}
      >
        <Button
          variant="outlined"
          sx={{
            height: "49px",
            width: "258px",
            borderRadius: "12px",
            color: "#FFFFFF",
            backgroundColor: "#080823", // Ensure the inner button is transparent,
            borderColor: "transparent",
            textTransform: "none", // Prevent text from being capitalized
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#080823", // Keep the same background on hover
              borderColor: "transparent", // Remove border color change on hover
            },
            "@media (max-width: 606px)": {
            width: "auto",
        },
        "@media (max-width: 392px)": {
            height: "auto"
        },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "25px", // Initial
              transition: "all 0.3s ease-in-out",
              "@media (max-width: 392px)": {
            fontSize: "14px",
            },
            }}
          >
            Sign Up!
          </Typography>
        </Button>
      </Box>
    );
  }
  export function SignInButton() {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery("(max-width:606px)");
    return (
      <Box
      onClick={() => navigate("/login")}
        sx={{
          height: "55px",
          width: isSmallScreen ? "auto": "195px",
          // display: "inline-block",
          borderRadius: "15px",
          background: "linear-gradient(120.39deg, #0077B6 7.46%, #03045E 68.49%)", // Gradient border
          padding: "3px 3px 3px 3px",
          margin: "0px 25px 0px 0px",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            background:
              "linear-gradient(137.05deg, #0077B6 29.9%, #00B4D8 75.89%)",
            boxShadow: "0px 0px 5px 1px #FFFFFFBF",
  
            "& .MuiTypography-root": {
              fontSize: "30px", // Increase text size on hover
            },
          },
          "@media (max-width: 606px)": {
            width: "auto",
        },
        "@media (max-width: 392px)": {
            height: "auto"
        },
        }}
      >
        <Button
          variant="outlined"
          sx={{
            height: "49px",
            width: "189px",
            borderRadius: "12px",
            color: "#FFFFFF",
            backgroundColor: "#080823", // Ensure the inner button is transparent,
            borderColor: "transparent",
            textTransform: "none", // Prevent text from being capitalized
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#080823", // Keep the same background on hover
              borderColor: "transparent", // Remove border color change on hover
            },
            "@media (max-width: 606px)": {
            width: "auto",
        },
        "@media (max-width: 392px)": {
            height: "auto"
        },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "25px", // Initial
              transition: "all 0.3s ease-in-out",
              "@media (max-width: 392px)": {
            fontSize: "14px",
            },
            }}
          >
            Sign In
          </Typography>
        </Button>
      </Box>
    );
  }