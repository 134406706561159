import React, { useState, useEffect } from "react";
import { motion, useScroll, useSpring } from 'framer-motion';


import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography } from "@mui/material";
import Partial from "./icons/Partialv1.png";
import Partial2 from "./icons/Partialv2.png";
import Rewards from "./icons/Rewardsv1.png";
import Rewards2 from "./icons/Rewardsv2.png";
import Secure from "./icons/Securev1.png";
import Secure2 from "./icons/Securev2.png";
import Trans from "./icons/Transv1.png";
import Trans2 from "./icons/Transv2.png";
import Simple from "./icons/Simplev1.png";
import Simple2 from "./icons/Simplev2.png";
import Smile from "./icons/Smilev1.png";
import Smile2 from "./icons/Smilev2.png";
import { Hero } from "./Hero";
import { SignInButton, SignUpButton } from "./HeaderButtons";
import { Typewriter } from "react-simple-typewriter";
import portsImage from "./ports_logo.png";
import styles from "./Home.module.css"; // Adjust the path if necessary
import CardImage from "./icons/CardImage.png";
import StockImage from "./icons/StockImage.png";
import Features from "./Features";
import GetStarted from "./GetStarted";
import OurStory from "./OurStory";
import {ReactComponent as PortsSVG} from "./icons/Ports.svg";
import {AnimatedSection} from "./AnimatedSection";

function PortfolioCard({ alt, delay }) {
  return (
    <div className={styles.portfolioCard} style={{ animationDelay: `${delay}s` }}>
      <img
        src={CardImage}
        alt={alt}
        width={270}
        height={340}
      />
    </div>
  );
}

function StockCard({ alt, delay }) {
  return (
    <div className={styles.portfolioCard}>
      <img
        src={StockImage}
        alt={alt}
        width={382}
        height={106}
      />
    </div>
  );
}

function LandingPage() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoaded ? 1 : 0 }}
      transition={{ duration: 2 }}
    >
      <AnimatedSection delay={1}>
        <Grid container spacing={3} sx={{ backgroundColor: "#080823", padding: "10px", alignItems: "center" }}>
          <Grid item md={6} xs={4}>
            <img
              src={portsImage}
              alt="Ports Logo"
              style={{ width: "140px", padding: "10px" }}
            />
          </Grid>
          <Grid
            container
            spacing={3}
            size="grow"
            sx={{ alignItems: "center", justifyContent: "right" }}
          >
            <Grid item md={6} xs={4}>
              <SignUpButton />
            </Grid>
            <Grid item md={6} xs={4}>
              <SignInButton />
            </Grid>
          </Grid>
        </Grid>
      </AnimatedSection>

      <AnimatedSection delay={1}>
        <Grid container spacing={0} sx={{ backgroundColor: "#FFFFFF", justifyContent: "center", alignItems: "center", paddingTop: "50px" }}>
          <Grid item md={8} xs={12}>
            <PortsSVG style={{ width: "100px", height:"100px" }} />
          </Grid>
          <Grid>
            <Typography
              sx={{
                fontSize: "35px",
                color: "#000000",
                fontWeight: "bold",
                textAlign: "center",
                paddingLeft: "10px",
              }}
            >
              Ports
            </Typography>
          </Grid>
        </Grid>
      </AnimatedSection>
      
      {/* Hero Section */}
      <AnimatedSection delay={2}>
        <Grid container spacing={0} sx={{ backgroundColor: "#FFFFFF", justifyContent: "center", alignItems: "center" }}>
          <Grid item md={8} xs={12}>
            <Hero />
          </Grid>
        </Grid>
      </AnimatedSection>

      <AnimatedSection delay={2}>
        <section className={styles.portfolioSection}>
          <div className={styles.portfolioGrid}>
            <PortfolioCard alt="Growth Port"  className={styles.PortfolioCard} delay={0} />
            <div className={styles.portfolioSection} style={{ marginTop: "10rem" }}>
              <h2>Check out the ports.</h2>
              <p>(and build some of your own!)</p>
            </div>
            <PortfolioCard alt="Tech Port" className={styles.PortfolioCard} delay={0.5} />
          </div>
          <div className={styles.portfolioGridBottom}>
            <PortfolioCard alt="Value Port" className={styles.PortfolioCard} delay={1} />
            <PortfolioCard alt="Dividend Port" className={styles.PortfolioCard} delay={1.5} />
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection delay={2}>
        <section className={styles.stockSection}>
          <h2>Add to your collection.</h2>
          <div className={styles.stockGrid1}>
            <StockCard alt="NVDA Stock Card" delay={0} />
            <StockCard alt="AAPL Stock Card" delay={0.2} />
            <StockCard alt="MSFT Stock Card" delay={0.4} />
          </div>
          <div className={styles.stockGrid2}>
            <StockCard alt="NVDA Stock Card" delay={0} />
            <StockCard alt="AAPL Stock Card" delay={0.2} />
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection delay={2}>
        <section className={styles.descriptionSection} style={{ marginTop: "10rem" }}>
          <h2 >Simple, transparent pricing</h2>
          <p style={{margin:"3rem"}}>By using a proprietary algorithm to calculate an expense ratio for every port, 
            we ensure that you get the best deal based on risk, volatility, performance, and many other metrics.</p>
        </section>
      </AnimatedSection>

      <AnimatedSection delay={2}>
        <Features />
      </AnimatedSection>

      <AnimatedSection delay={2}>
        <GetStarted />
      </AnimatedSection>

      <AnimatedSection delay={2}>
        <OurStory />
      </AnimatedSection>

      <Box sx={{ backgroundColor: "#26317A", height: "300px" }} />
    </motion.div>
  );
}

export { LandingPage as KLandingPage };