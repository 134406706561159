import styles from './GetStarted.module.css';
import {ReactComponent as Arrow} from './icons/Arrow.svg';
export default function GetStarted() {
    return (
      <section className={styles.getStarted}>
        <h2 className={styles.getStartedTitle}>Let's get started!</h2>
        <div className={styles.steps}>
          <div className={styles.step}>Sign Up</div>
          <Arrow className={styles.arrow} />
          <div className={styles.step}>Browse Ports</div>
          <Arrow className={styles.arrow} />
          <div className={styles.step}>Invest or Build</div>
        </div>
      </section>
    )
  }