import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackArrow from "./back_arrow.png";
import * as Mui from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getPort, getChartData, getLiveIndex, setToPending, investPort, approvePort, rejectPort  } from "../user.js";
import {editPort, dailyUpdatePortfolio} from "../user.js";
import { Line } from "react-chartjs-2";
import SecureStorage from 'react-secure-storage';
import {colorCode, RiskBar} from "../Cards/cardv2";
import {Delete} from "../user.js";


const adminUserIds = [16, 15, 51];

function LineGraph({ data, index, labels, unit }) {
  if (data === undefined || labels === undefined) {
    return <div>Loading...</div>;
  }
  // for(let label in labels){
  //   labels[label] = new Date(labels[label]*1000);
  // }
  // console.log("labels: ",labels);

  const datas = {
    labels: labels,
    datasets: [
      {
        label: 'Back Test Data',
        data: data,
        fill: false,
        // backgroundColor: '#949494',
        // borderColor: '#1B263B', // Default border color
        segment: {
          borderColor: (context) => {
            return context.p0DataIndex < index ? "#949494" : '#1B263B';
          },
        },
          pointBackgroundColor: (context) => {
            return context.dataIndex === data.length - 1 ? '#1B263B' : "#949494";
          },
          pointRadius: (context) => {
            return context.dataIndex === data.length - 1 ? 5 : 0; // Make the last point radius bigger
          },
          pointBorderWidth: 0,
          pointBorderColor: 'transparent',
          
        cubicInterpolationMode: 'monotone',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#1B263B',
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (context) {
            const date = new Date(context[0].parsed.x * 1000);
            if (unit === 'day') {
              return date.toLocaleDateString()
            } else{
              return date.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
              });
            }
            
          },
          label: function (context) {
            const label = context.dataIndex < index ? 'BackTest Data' : 'Live Data';
            const value = context.raw;
            return `${label}: ${value.toFixed(2)}`;
          },
          labelColor: function(context) {
            return {
              borderColor: context.dataIndex < index ? "#949494" : '#1B263B',
              backgroundColor: context.dataIndex < index ? "#949494" : '#1B263B',
            };
          }
        },
      },
    },
    // interaction: {
    //   mode: 'x',
    //   intersect: false,
    //   axis: 'xy',
    // },
    scales: {
      x: {
        type: 'time',
        // time: {
        //   unit: 'minute', // Adjust the unit as needed (e.g., 'minute', 'hour', 'day')
        // },
        ticks: {
          display: true,
          callback: function(value, index, values) {
          const date = new Date(value * 1000); // Convert Unix timestamp to JavaScript Date object
          return date.toLocaleString('en-US', {
            // year: 'numeric',
            // month: '2-digit',
            // day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            hour12: true,
          });
        },

          // callback: function(value, index, values) {
          //   const date = new Date(value * 10000); // Convert Unix timestamp to JavaScript Date object
          //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time as HH:MM
          // },
          color: '#1B263B',
        },
        title: {
          display: false,
          text: 'Date',
          color: '#1B263B',
        },
      },
      y: {
        ticks: {
          display: true,
          text: 'Value',
          color: '#1B263B',
        },
        grid: {
          color: '#949494',
          borderColor: 'red',
        },
      },
    },
  };
  return (
    <div style={{ textAlign: "center", width: "900px", minHeight: "350px" }}>
      <Line data={datas} options={options} className="canvas" />
    </div>
  );
};

function DescriptionField({ description }) {
  return (
    <Mui.TextField
      value={description}
      readOnly
      multiline
      variant="outlined"
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexGrow: 1,
        marginTop: '10px',
        backgroundColor: '#F0F0F5',
        border: '2px solid #1B263B',
        borderRadius: '5px',
        color: '#1B263B', // Text color
        cursor: 'default', // Change cursor to default
        resize: 'none', // Disable resizing
        outline: 'none', // Remove focus outline
        padding: '3px', // Add padding for better readability
        fontFamily: 'inherit', // Inherit font from parent
        fontSize: 'inherit', // Inherit font size from parent
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent', // Remove the default border
          },
          '&:hover fieldset': {
            borderColor: 'transparent', // Remove the hover border
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent', // Remove the focused border
          },
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#1B263B', // Ensure text color is applied when disabled
        },
      }}
      InputProps={{
        readOnly: true,
      }}
    />
  );
}


function PortInfo() {
  const userId = SecureStorage.getItem("userId");
  const navigate = useNavigate();
    const { id } = useParams();
    // id represents the id of the portfolio
    // type represents the type of the portfolio Public, Private, Pending
    const [reserve, setReserve] = React.useState(0);
    
    const timeframes = ["1D", "1W", "1M", "1Y", "ALL"];
    const timeframesTOinterval = {
      "1D": "minute",
      "1W": "hour",
      "1M": "day",
      "1Y": "day",
      "ALL": "day",
    };
    const [index, setIndex] = React.useState(0);
    const [selectedTimeframe, setSelectedTimeframe] = React.useState("1D");
    console.log("selectedTimeframe: ",selectedTimeframe);
    const [data, setData] = React.useState({});
    const [graphData, setGraphData] = React.useState({});
    const [isAdmin, setIsAdmin] = React.useState(adminUserIds.includes(userId));
    const [isOwner, setIsOwner] = React.useState(false);
    const [showStockModal, setShowStockModal] = React.useState(false);
    const [showStockButton, setShowStockButton] = React.useState(false);
    const [showInvesting, setShowInvesting] = React.useState(false);
    const [showApproval, setShowApproval] = React.useState(false);
    const [isInvesting, setIsInvesting] = React.useState(false);
    const [showEditButton, setShowEditButton] = React.useState(false);
    const [rerender, setRerender] = React.useState(0);
    const [showDeleteButton, setShowDeleteButton] = React.useState(false);


    const handleDelete = async () => {
      await Delete(id);
      navigate("/home");
    }
    const decodeHtmlEntities = (text) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      return doc.documentElement.textContent;
    };
    React.useEffect(() => {
      const fetchData = async () =>{
        let portData = await getPort(id)
        decodeHtmlEntities(portData);
        setData(portData)
        // console.log("ratio2: ",portData.ratio);
        setReserve(portData.reserve);
        let ratio = 1;
        if(portData.ratio !== 0){
        console.log("ratio1: ",portData.ratio);
          ratio = portData.ratio;
          setIsInvesting(true);
        }
        setIsOwner(userId === portData.author.id);
        let graphData = await getChartData(id,ratio, selectedTimeframe, timeframesTOinterval[selectedTimeframe]);
        setGraphData(graphData);
        const index = await getLiveIndex(graphData.dateHist, portData.creationDate);
        setIndex(index);
      }
      console.log("fetching data");
      fetchData();
      const intervalId = setInterval(fetchData, 60000);

      return () => clearInterval(intervalId); 
    },[selectedTimeframe,id,rerender]);


    React.useEffect(() => {
      if(isOwner && isAdmin){
        setShowDeleteButton(true);
      }
      if(data.status){
        console.log("isOwner: ",isOwner);
        console.log("isAdmin: ",isAdmin);
        console.log("status: ",data.status);
      if(data.status === "private" && !isOwner){
        navigate("/home")
      } 
  
      if(data.status === "pending" ){
        
        if(!isOwner){
          if(!isAdmin){
            navigate("/home")
          }
        }
        
          if(isAdmin){
            setShowApproval(true);
          }
        
    }
    if(isOwner || isAdmin){
      setShowStockButton(true);
    }
    if (data.status === "private" && isOwner){
      setShowEditButton(true);
    }
  }

    },[data]);

    console.log("showApproval: ",showApproval);
    console.log("showInvestments: ",showInvesting);
    
  
  return(
   <Mui.Box sx={{
    display: "flex",
    flexDirection: "column",   
    width: "100%",
    backgroundColor: "#F6F5F5",
    padding: "30px",
    overflow: "auto",
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '-ms-overflow-style': 'none',  // IE and Edge
    'scrollbar-width': 'none',     // Firefox
    marginBottom: "50px",
    
   }}>
    {showStockModal && <StockModal stockData={data.portStocks} setShowStockModal={setShowStockModal}/>}
    <Mui.Box sx = {{
      display: "flex",
      gap: "10px",
      zIndex: "1",
    }}>
    <Mui.Button
    sx={{
      width: "149px",
      height: "39px",
      borderRadius: "10px",
      border: "1px solid #080823",
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    onClick = {() => navigate("/home")}
    >
      <img src={BackArrow} style={{ marginRight: "8px" }} /> <Mui.Typography
      sx = {{
        color: "#080823",
        fontWeight: "700",
        fontSize: "25px",
        textAlign: "center",
      }}
      >Back</Mui.Typography>
      </Mui.Button>
      {data.status === "private" &&(
        <Mui.Button
    sx={{
      width: "149px",
      height: "39px",
      borderRadius: "10px",
      border: "1px solid #080823",
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    onClick = {() => setToPending(data.id)}
    >
        <Mui.Typography
      sx = {{
        color: "#080823",
        fontWeight: "700",
        fontSize: "25px",
        textAlign: "center",
      }}
      >Publish</Mui.Typography></Mui.Button>
      )}
      {showStockButton && (<Mui.Button  sx={{ 
      width: "149px",
      height: "39px",
      borderRadius: "10px",
      border: "1px solid #080823",
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
            }}
            onClick = {() => setShowStockModal(true)}
            >
              <Mui.Typography
      sx = {{
        color: "#080823",
        fontWeight: "700",
        fontSize: "25px",
        textAlign: "center",
      }}
      >Stocks</Mui.Typography>
          </Mui.Button>)}

          {showEditButton && (<Mui.Button  sx={{ 
      width: "149px",
      height: "39px",
      borderRadius: "10px",
      border: "1px solid #080823",
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
            }}
            onClick = {() => navigate(`/edit/${data.id}`)}
            >
              <Mui.Typography
      sx = {{
        color: "#080823",
        fontWeight: "700",
        fontSize: "25px",
        textAlign: "center",
      }}
      >Edit</Mui.Typography>
          </Mui.Button>)}

          {showDeleteButton && (<Mui.Button  sx={{ 
      width: "149px",
      height: "39px",
      borderRadius: "10px",
      border: "1px solid #080823",
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
            }}
            onClick = {handleDelete}>

              <Mui.Typography
      sx = {{
        color: "#080823",
        fontWeight: "700",
        fontSize: "25px",
        textAlign: "center",
      }}
      >Delete</Mui.Typography>
          </Mui.Button>)}
      
    </Mui.Box>
    <Mui.Box sx = {{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}>
      <Mui.Typography sx = {{
        color: "#080823",
        fontWeight: "700",
        fontSize: "35px",
        lineHeight: "42.7px",
  
      }}>
        {data.title}
      </Mui.Typography>
      <Mui.Typography sx = {{
        color: "#61616E",
        fontSize: "18px",
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        By {data.author?.username ? (data.author.username) : ""}
  
        <Mui.Box sx = {{
                  display: "flex",
                  gap: "5px",
                }}>
                  {data.author ? data.author.tags.map((role) => (
                    <Mui.Typography
            sx={{
              width: "34px",
              height: "13px",
              backgroundColor: "#080823",
              borderRadius: "5px",
              padding: ".5px 9px .5px 9px",
              color: "white",
              fontSize: "8px",
              textAlign: "center",
              alignSelf: "center",
              marginLeft: "5px",
            }}
          >
            {role.name}
          </Mui.Typography>)) : ""}
                </Mui.Box>
      </Mui.Typography>
      <div
    style={{
      display: 'flex',
      width: '900px',
      justifyContent: 'space-between', // Adjusts spacing between items
      alignItems: 'center', // Aligns items vertically in the center
    }}
  >
    {timeframes.map((timeframe) => (
      <div
        key={timeframe}
        style={{
          color: "#080823",
          cursor: "pointer", // Changes cursor to pointer on hover
          transition: "color 0.3s ease", // Smooth transition for hover effect
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.color = "#1e90ff"; // Hover color
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.color = "#080823"; // Reset color
        }}
        onClick={(e) => setSelectedTimeframe(timeframe)}
      >
        {timeframe}
      </div>
    ))}
  </div>
  
      <LineGraph data={graphData.valueHist} labels = {graphData.dateHist} index = {index} unit={timeframesTOinterval[selectedTimeframe]} />
      <Mui.Box sx = {{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
      }}>
        <Mui.Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between", 
      alignItems: "center", 
      gap: "15px", 
      paddingTop: "20px",
      maxHeight: "100%",
      transform: 'scale(.9)',
    }}
  >
          <Detail name = {"Value"} >
          ${data.lastValue}
          </Detail>
          <Detail name = {"Annual Return"} >
          {colorCode(data.annualReturn, "percent")}
          </Detail>
          <Detail name = {"Daily Change"} >
          {colorCode(data.dailyPnl, "dollar")}
          </Detail>
          <Detail name = {"Risk Index"} >
          <Mui.Box
            sx={{
              transform: 'scale(1.5)', // Scale up the RiskBar component
              transformOrigin: 'left', // Adjust the origin as needed
            }}
          >
            <RiskBar props={data} />
          </Mui.Box>
          </Detail>
          <Detail name = {"Expense Ratio"} >
            {data.expenseRatio}
          </Detail>
        </Mui.Box>
        <Mui.Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between", 
      alignItems: "center", 
      gap: "15px", 
      paddingTop: "20px",
      maxHeight: "100%",
      transform: 'scale(.9)',
    }}
  >
          <Detail name = {"Sharpe Ratio"} >
          {data.sharpeRatio}
          </Detail>
          <Detail name = {"Max Drawdown"} >
          {colorCode(data.maxDrawdown,'percent')}
          </Detail>
          <Detail name = {"PnL"} >
            {colorCode(data.totalPnL, "percent")}
          </Detail>
        </Mui.Box>
            {showInvesting && <InvestmentTabs reserve={reserve} portId={data.id} isInvesting={isInvesting} setRerender={setRerender} rerender={rerender}/>}
            {showApproval &&  <Approval portId={data.id}/>}
      </Mui.Box>
    
   </Mui.Box>
   <DescriptionField description={data.description}/>
   </Mui.Box>
  );
  }

  function InvestmentTabs({reserve, portId, isInvesting,setRerender, rerender}) {

    const [buyInput, setBuyInput] = React.useState("");
    const [sellInput, setSellInput] = React.useState("");
    const [showSell, setShowSell] = React.useState(false);
    if((reserve !== 0 || isInvesting) && !showSell){
      setShowSell(true);
    }
    const handleBuyChange = (event) => {
      if (/^\d*\.?\d*$/.test(event.target.value)) {
        setBuyInput(event.target.value);
      }
      
    };
    const handleSellChange = (event) => {
      if (/^\d*\.?\d*$/.test(event.target.value)) {
      setSellInput(event.target.value);
      }
    };
  
    async function SellButtonPressed(){
      await investPort(portId, SecureStorage.getItem("userId"), -parseFloat(sellInput));
      setRerender(rerender+1);
    }
    async function BuyButtonPressed(){
      await investPort(portId, SecureStorage.getItem("userId"), parseFloat(buyInput));
      setRerender(rerender+1);
  
    }
  
    return (
      <>
      <Mui.Box sx = {{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
  {reserve !== 0 && (
    <Mui.Typography
      sx={{
        color: "#4F1313",
        fontSize: "15px",
      }}
    >
      {reserve > 0 ? "Buying" : "Selling"} ${Math.abs(reserve)} @ OPEN (9:30 EST)
    </Mui.Typography>
  )}
  
  <Mui.Typography sx = {{
      color: "#080823",
      fontSize: "20px",
      fontWeight: "700",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "10px",
    }}>
      Buy
    </Mui.Typography>
  <Mui.TextField
            id="outlined-basic"
            placeholder="$5 minimum"
            value={buyInput} 
            onChange={handleBuyChange}
            sx={{
              width: "397px", // Set the width of the Mui.TextField
              paddingBottom: "20px",
              ".MuiOutlinedInput-root": {
                height: "42px", // Set the height of the input field
                borderRadius: "10px", // Rounded corners
                "& fieldset": {
                  border: "0.5px solid #08082380", // Border styling
                },
              },
              ".MuiInputBase-input": {
                padding: "8px", // Adjust padding inside the input
                fontSize: "20px", // Adjust font size if necessary
              },
            }}
          />
          <Mui.Button
            
            sx={{ 
              border: "2px solid #080823",
              borderRadius: "10px",
              height: "42px",
              width: "200px",
              color: "#080823",
              fontSize: "17px",
              textTransform: "none",
            }}
            onClick={BuyButtonPressed}
          >
            Confirm Buy Order
            </Mui.Button>
  {showSell &&
  (<><Mui.Typography sx = {{
      color: "#080823",
      fontSize: "20px",
      fontWeight: "700",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "10px",
    }}>
      Sell
    </Mui.Typography>
  <Mui.TextField
            id="outlined-basic"
            placeholder="$5 minimum"
            value={sellInput}
            onChange={handleSellChange}
            sx={{
              width: "397px", // Set the width of the Mui.TextField
              paddingBottom: "20px",
              ".MuiOutlinedInput-root": {
                height: "42px", // Set the height of the input field
                borderRadius: "10px", // Rounded corners
                "& fieldset": {
                  border: "0.5px solid #08082380", // Border styling
                },
              },
              ".MuiInputBase-input": {
                padding: "8px", // Adjust padding inside the input
                fontSize: "20px", // Adjust font size if necessary
              },
            }}
          />
          <Mui.Button
            onClick={SellButtonPressed}
            sx={{ 
              border: "2px solid #080823",
              borderRadius: "10px",
              height: "42px",
              width: "200px",
              color: "#080823",
              fontSize: "17px",
              textTransform: "none",
            }}
          >
            Confirm Sell Order
            </Mui.Button></>)}
        </Mui.Box>
        </>
          
    );
  }
  
  
  function StockModal({stockData, setShowStockModal}) {
    return (
      <Mui.Box sx = {{
        position: 'absolute',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backdropFilter: 'blur(5px)',
  
        width: '100%',
        height: '100%',
        zIndex: '100',
      }}
      onClick={() => setShowStockModal(false)}
      >
        <Mui.Box
      sx={{
        background: 'white',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        height: '400px',
        overflow: 'auto',
        zIndex: '101',
      }}
    >
      {
        stockData.map((stock) => (
          <Mui.Typography
            sx={{
              fontSize: '20px',
              color: stock.status === 'active' ? '#080823' : 'red',
              marginBottom: '10px',
            }}
          >
            {stock.stockId.ticker} - {stock.curPerc.toFixed(2)}%
          </Mui.Typography>
        ))
      }
    </Mui.Box>
  
      </Mui.Box>
    )
      
  }
    function Approval({portId,status}){
      const [reason, setReason] = React.useState("");
    
    const handleApproveClick = async () => {
      if(status === "pending"){
      const response = await approvePort(portId);
      } else{
        await editPort(portId);
        await dailyUpdatePortfolio(portId);
      }
    }
    const handleRejectClick = async () => {
      const response = await rejectPort(portId,reason);
    }
    
    return(
      <Mui.Box sx = {{
      gap: "20px",
      alignItems: "center",
      }}>
                <Mui.Button
              sx={{ 
                border: "2px solid #080823",
                borderRadius: "10px",
                height: "42px",
                width: "200px",
                color: "#080823",
                fontSize: "17px",
                textTransform: "none",
              }}
              onClick={handleApproveClick}
            >
              Approve
              </Mui.Button>
              <Mui.Button
              sx={{ 
                border: "2px solid #080823",
                borderRadius: "10px",
                height: "42px",
                width: "200px",
                color: "#080823",
                fontSize: "17px",
                textTransform: "none",
              }}
              onClick={handleRejectClick}
            >
              Reject
              </Mui.Button>
              <Mui.TextField          id="outlined-basic"
              placeholder="Reason for rejection"
              value={reason} // Bind searchQuery state to the Mui.TextField
              onChange={(event) => setReason(event.target.value)} // Update state on input change
              sx={{
                width: "100%", // Set the width of the Mui.TextField
                ".MuiOutlinedInput-root": {
                  height: "70px", // Set the height of the input field
                  borderRadius: "5px", // Rounded corners
                  "& fieldset": {
                    border: "0.5px solid #08082380", // Border styling
                  },
                },
                ".MuiInputBase-input": {
                  padding: "8px", // Adjust padding inside the input
                  paddingLeft: "30px",
                  fontSize: "20px", // Adjust font size if necessary
                },
              }}/>
    
      </Mui.Box>);
    }
  
  function Detail({name, children}){
  return(<Mui.Box sx = {{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "384px"
  }}>
    <Mui.Typography sx = {{
      color: "#080823",
      fontSize: "25px",
      fontWeight: "700",
    }}>
      {name}
    </Mui.Typography>
   
    <Mui.Typography sx = {{
            fontSize: "25px",
            fontWeight: "700",
            color: "#080823",
          }}>
            {children}
    </Mui.Typography>
  
  </Mui.Box>);
  }









export default PortInfo;